<template lang="">
  <div class="w-100">
    <div
      class="container mx-auto lg:px-3 px-5 py-12 flex flex-col lg:flex-row lg:justify-between"
    >
      <div class="flex flex-col font-bold flex-grow items-center">
        <h2
          class="text-blue-light px-6 text-center text-3xl font-semibold"
        >
          <span class="lg:inline block">25,365</span> Cars Ready to Bid
        </h2>
        <div class="flex-row w-full px-3 items-stretch hidden">
          <SelectInput
            :options="makes"
            :value="params.make"
            @valueChange="updateMake"
            class="flex-grow mb-0"
            placeholder="Make"
          />
          <SelectInput
            :options="models"
            :value="params.model"
            @valueChange="updateModel"
            class="flex-grow mb-0"
            placeholder="Model"
          />
          <SelectInput
            :options="filters['odometers']"
            class="flex-grow mb-0"
            :value="selectedOdometer"
            @valueChange="odometerSelected"
            placeholder="Odometer"
            :selectedValue="selectedOdometer ? {'value':selectedOdometer,'label':`${selectedOdometer} miles`} : null"
          />

          <SelectInput
            :options="filters['years']"
            class="flex-grow mb-0"
            :value="selectedYear"
            @valueChange="yearSelected"
            placeholder="Year"
            :selectedValue="selectedYear ? {'value':selectedYear,'label':`${selectedYear.split('-').join(' to ')}`} : null"
          />
          <button
            type="button"
            @click="gotoSearch"
            class="bg-blue text-white w-full h-11 text-sm uppercase ml-2"
          >
            Search Cars
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SelectInput from "../components/SelectInput.vue";
import { mapGetters } from "vuex";

export default {
  name: "Search Banner",
  components: {
    SelectInput,
  },
  computed: {
    ...mapGetters({
      makeObjects: "makes",
      params: "searchParams",
      filters: "filters",
    }),
  },
  watch: {
    makeObjects() {},
    params(value) {
      if (value["make"]) {
        this.modelsForMake(value["make"]);
      }
      if (value["model"]) {
        this.seriesForModel(value["model"]);
      }
      if (value["series"]) {
        this.seriesSelected(value["series"]);
      }
      if (value["odometer_min"] & value["odometer_max"]) {
        this.odometerSelected(
          value["odometer_min"] + "-" + value["odometer_max"]
        );
      }
      if (value["from_year"] & value["to_year"]) {
        this.yearSelected(value["from_year"] + "-" + value["to_year"]);
      }
    },
  },
  data() {
    return {
      makes: [],
      models: [],
      series: [],
      selectedOdometer: null,
      selectedYear: null,
    };
  },
  methods: {
    gotoSearch() {
      this.loadDeals(this.params);
      if (this.$route.name != "Search") {
        this.$router.push("/search");
      }
    },
    loadDeals(params) {
      this.$store.dispatch("fetchDeals", params);
    },

    updateMake(make) {
      this.params.make = make;
      this.params.model = "";
      this.params.series = "";
      this.$store.dispatch("setSearchParams", { ...this.params });
    },
    updateModel(model) {
      this.params.model = model;
      this.params.series = "";
      this.$store.dispatch("setSearchParams", { ...this.params });
    },
    updateSeries(series) {
      this.params.series = series;
      this.$store.dispatch("setSearchParams", { ...this.params });
    },

    modelsForMake(makeName) {
      this.params.make = makeName;
      this.models = [];
      this.series = [];
      if (!makeName) {
        return;
      }
      const makeObj = this.makeObjects.find(
        (item) => item.name == this.params.make
      );
      this.models = makeObj.models.map((item) => item.name);
    },
    seriesForModel(modelName) {
      this.params.model = modelName;
      this.series = [];

      if (!modelName) {
        return;
      }
      const makeObj = this.makeObjects.find(
        (item) => item.name == this.params.make
      );
      const model = makeObj.models.find(
        (item) => item.name == this.params.model
      );
      this.series = model.series.map((item) => item.name);
    },
    seriesSelected(seriesName) {
      this.params.series = seriesName;
    },
    odometerSelected(odometer) {
      if (!odometer) {
        return;
      }
      const odoValues = odometer.split("-");
      if (odoValues.length != 2) {
        return;
      }
      this.selectedOdometer = odometer;
      this.params.odometer_min = odoValues[0];
      this.params.odometer_max = odoValues[1];
    },
    yearSelected(year) {
      if (!year) {
        return;
      }
      const yearValues = year.split("-");
      if (yearValues.length != 2) {
        return;
      }
      this.selectedYear = year;
      this.params.from_year = yearValues[0];
      this.params.to_year = yearValues[1];
    },
  },
  mounted() {
    this.makes = this.makeObjects.map((item) => item.name);
    if (this.params.make) {
      this.modelsForMake(this.params.make);
    }
    if (this.params.model) {
      this.seriesForModel(this.params.model);
    }
    if (this.params.odometer_max) {
      this.odometerSelected(
        `${this.params.odometer_min}-${this.params.odometer_max}`
      );
    }
    if (this.params.to_year) {
      this.yearSelected(`${this.params.from_year}-${this.params.to_year}`);
    }
  },
};
</script>
<style lang=""></style>
