<template lang="">
  <div class="shadow-lg-dark mt-14 p-6 pt-12 bg-white border relative">
    <div class="flex flex-row justify-center">
      <button
        @click="currentView = 1"
        :class="[currentView == 1 ? 'selected text-white special-shadow' : '']"
        class="vehicle-details-btn w-3/12 h-14 relative"
      >
        Vehicle Details
      </button>
      <button
        @click="currentView = 2"
        :class="[currentView == 2 ? 'selected text-white special-shadow' : '']"
        class="cost-breakdown-btn mx-4 w-3/12 h-14 relative"
      >
        Cost Breakdown
      </button>
      <button
        @click="currentView = 3"
        :class="[currentView == 3 ? 'selected text-white special-shadow' : '']"
        class="timeline-btn w-3/12 h-14 relative"
      >
        Timeline
      </button>
    </div>

    <!-- Vehicle Details -->
    <div v-if="currentView == 1" class="w-full">
      <VehicleDetails />
    </div>

    <!-- Cost Breakdown -->
    <div v-if="currentView == 2" class="w-full">
      <CostBreakdown />
    </div>

    <!-- Timeline -->
    <div v-if="currentView == 3" class="w-full">
      <Timeline />
    </div>
  </div>
</template>
<script>
import VehicleDetails from '../components/VehicleDetails.vue';
import CostBreakdown from '../components/CostBreakdown.vue';
import Timeline from '../components/Timeline.vue';
export default {
  name: 'Details Section',
  components: {
    VehicleDetails,
    CostBreakdown,
    Timeline,
  },
  methods: {},
  data() {
    return {
      currentView: 1,
    };
  },
};
</script>
