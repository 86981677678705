<template lang="">
  <div class="lg:hidden flex flex-col items-stretch">
    <h5 class="text-lg font-medium p-3">Cost Breakdown</h5>
    <hr class="mx-3" />
  </div>
  <div
  v-if="deal"
    class="flex flex-col lg:items-center items-stretch px-5 lg:pt-14 lg:mt-14 mt-8 lg:mb-8"
  >
    <div
      class="flex flex-row mb-3 text-sm text-gray-medium items-center justify-center"
    >
      <i class="icon map gray small mr-1"></i>
      Delivery to <span class="text-black-light ml-1"> Austin 73301</span>
    </div>
    <div class="flex flex-row w-full justify-center lg:mb-10">
      <div class="lg:w-5/12 w-full flex flex-row">
        <input
          placeholder="Enter Zip Code"
          class="border p-3 py-2 w-full flex-grow text-sm"
          type="text"
          name="zip-code"
        />
        <button
          type="button"
          class="bg-blue text-white h-11 text-sm uppercase ml-2 w-1/3"
        >
          Update
        </button>
      </div>
    </div>
    <div
      class="flex lg:flex-row flex-col w-full justify-center items-stretch mb-8"
    >
      <div class="block lg:hidden pt-8">
        <div
          class="h-full border-2 border-black-light rounded p-5 flex flex-col shadow-lg-dark justify-center items-center"
        >
          <img src="/images/icons/badge.png" class="mx-auto" />
          <div class="flex flex-row mx-auto mb-4 mt-8 w-9/12">
            <div class="text-black-lighter flex-grow">Retail Value</div>
            <div class="ml-4 font-medium">${{deal.retail_value.toLocaleString()}}</div>
          </div>
          <div class="flex flex-row mx-auto mb-5 w-9/12">
            <div class="text-black-lighter flex-grow">Cost of Car</div>
            <div class="ml-4 font-medium">${{deal.fair_bid.toLocaleString()}}</div>
          </div>
          <div class="flex flex-row mx-auto mb-5 w-9/12">
            <div class="text-black-lighter flex-grow">Sales Tax</div>
            <div class="ml-4 font-medium">${{deal.sales_tax.toLocaleString()}}</div>
          </div>
          <div
            class="bg-black-light w-full text-center font-medium text-xl text-white px-5 py-3 rounded"
          >
            You Saved ${{deal.savings.toLocaleString()}}
          </div>
        </div>
      </div>

      <div class="lg:w-1/3 w-full lg:pr-3 lg:py-8 pt-8">
        <div class="p-1 border rounded-sm h-full">
          <CostBreakdownAccordian :title="`Retail Value Breakdown \$${deal.retail_value_breakdown.toLocaleString()}`">
            <div class="flex-col w-full px-4 py-6">
              <div class="flex flex-row mb-6">
                <div class="w-8/12 text-black-lighter">Retail Value</div>
                <div class="w-4/12 font-medium">${{deal.retail_value.toLocaleString()}}</div>
              </div>
<!--              <div class="flex flex-row">-->
<!--                <div class="w-8/12 text-black-lighter">Covid Inflation</div>-->
<!--                <div class="w-4/12 font-medium">${{deal.convid_inflation.toLocaleString()}}</div>-->
<!--              </div>-->
            </div>
          </CostBreakdownAccordian>
        </div>
      </div>

      <div class="lg:w-1/3 lg:pl-3 lg:py-8 w-full pt-4">
        <div class="p-1 border rounded-sm h-full">
          <CostBreakdownAccordian :title="`Cost of Car Breakdown \$${deal.cost_of_car_breakdown.toLocaleString()}`">
            <div class="flex-col w-full px-4 py-6">
              <div class="flex flex-row mb-6">
                <div class="w-8/12 text-black-lighter">Purchase Price</div>
                <div class="w-4/12 font-medium">${{deal.fair_bid.toLocaleString()}}</div>
              </div>
              <div class="hidden flex-row mb-6">
                <div class="w-8/12 text-black-lighter">Repair Expenses</div>
                <div class="w-4/12 font-medium">${{deal.repair_expenses.toLocaleString()}}</div>
              </div>
              <div class="flex flex-row mb-6">
                <div class="w-8/12 text-black-lighter">Wholesale Fee</div>
                <div class="w-4/12 font-medium">${{deal.auction_fee.toLocaleString()}}</div>
              </div>
              <div class="flex flex-row mb-6">
                <div class="w-8/12 text-black-lighter">FairPY Fee</div>
                <div class="w-4/12 font-medium">${{deal.fairpy_fee.toLocaleString()}}</div>
              </div>
<!--              <div class="flex flex-row">-->
<!--                <div class="w-8/12 text-black-lighter">Shipping Fee</div>-->
<!--                <div class="w-4/12 font-medium">${{deal.shipping_fee.toLocaleString()}}</div>-->
<!--              </div>-->
            </div>
          </CostBreakdownAccordian>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CostBreakdownAccordian from "./CostBreakdownAccordian.vue";
export default {
  name: "Cost Breakdown Tab",
  components: {
    CostBreakdownAccordian,
  },
  methods: {
    loadSelectedDetails() {
      this.$store.dispatch("fetchSelectedDeal");
    },
  },
  computed: {
    ...mapGetters({
      deal: "selectedDeal",
    }),
  },
  watch: {},
  mounted() {
    // this.loadSelectedDetails();
  },
};
</script>
