<template>
  <div class="w-100 lg:py-20 py-7 relative overflow-hidden">
    <div
        class="absolute z-0 left-0 right-0 top-0 bottom-0 flex flex-row justify-between items-center"
    >
      <img
          class="lg:w-auto w-full absolute left-0 top-0"
          src="/images/map-left.png"
      />
      <img
          class="lg:w-auto absolute right-0 bottom-36"
          src="/images/dots.png"
      />
    </div>

    <div id="section-start" class="container mx-auto lg:px-0 px-5 z-10 relative flex flex-col">
      <div class="flex lg:flex-row flex-col items-stretch">
        <div class="lg:w-9/12 w-full lg:pr-8 flex-shrink">
          <div class="flex flex-col h-full">
            <div
                class="large-image relative flex-grow w-full lg:h-full h-60 overflow-hidden"
            >
              <button
                  @click="previousGalleryItem"
                  v-if="currentImageIndex > 0"
                  class="absolute z-20 top-1/2 ml-2 -mt-4 shadow-xl"
              >
                <i class="icon xl left-arrow-gallery"></i>
              </button>
              <button
                  @click="nextGalleryItem"
                  v-if="currentImageIndex < galleryItems.length - 1"
                  class="absolute z-20 top-1/2 right-0 mr-2 -mt-4 shadow-xl"
              >
                <i class="icon xl right-arrow-gallery"></i>
              </button>
              <div class="absolute top-2 left-2 z-10 autoplay">
                <p class="rounded-sm px-2 py-1 text-white bg-blue text-xs bg-opacity-90">Sample Photo</p>
              </div>
              <!--              <div v-if="deal && deal.pdf" class="absolute z-10 bg-black-full bg-opacity-10 left-0 top-0 w-full h-full flex items-center justify-center">-->
              <!--                <a :href="deal.pdf" target="_blank" class="border-white px-6 py-2 text-white rounded bg-blue bg-opacity-90 mx-4 text-center"><span>Click here to see more details about the car</span></a>-->
              <!--              </div>-->
              <img :src="galleryItems[currentImageIndex].src"
                   class="absolute z-0 left-0 top-0 w-full h-auto object-cover"
              />
            </div>
            <div class="flex-shrink-0 flex flex-row w-full mt-5 relative">
              <button
                  v-if="galleryItems.length > 1"
                  @click="scrollThumbnailLeft"
                  class="absolute hidden lg:block z-10 top-1/2 ml-2 -mt-4 shadow-xl">
                <i class="icon xl left-arrow-gallery"></i>
              </button>
              <button
                  v-if="galleryItems.length > 1"
                  @click="scrollThumbnailRight"
                  class="absolute hidden lg:block z-10 top-1/2 right-0 mr-2 -mt-4 shadow-xl">
                <i class="icon xl right-arrow-gallery"></i>
              </button>
              <div class="thumbnails flex flex-row w-full overflow-x-auto no-scrollbar">
                <div
                    @click="setCurrentImage(index)"
                    v-for="(item, index) in galleryItems"
                    :key="index"
                    :class="[
                    index == galleryItems.length - 1 ? 'mr-0' : 'mr-3',
                    'thumbnail_' + index,
                  ]"
                    class="w-31 h-22 flex-shrink-0 relative"
                >
                  <div
                      v-if="currentImageIndex == index"
                      class="absolute top-0 left-0 right-0 bottom-0 bg-gray-800 bg-opacity-50"
                  ></div>
                  <img
                      :src="item.thumbnail"
                      class="w-full h-full object-cover index-control"
                      draggable="false"
                  />
                </div>
              </div>

            </div>

            <div v-if="deal && deal.pdf" class="w-full flex mt-8 items-center justify-center">
              <a :href="deal.pdf" target="_blank"
                 class="border-white px-6 py-2 text-white rounded bg-blue mx-4 text-center text-xs md:text-sm lg:text-sm xl:text-sm 2xl:text-sm"><span>Click here to see more details about the car</span></a>
            </div>
          </div>
        </div>
        <div class="lg:w-3/12 w-full mt-7 lg:mt-0 flex-shrink-0 flex-grow-0">
          <DetailsItem/>
        </div>
      </div>

      <div class="lg:block hidden">
        <DetailsTabSection/>
      </div>
      <div class="lg:hidden block">
        <DetailsListSection/>
      </div>

      <div class="w-full lg:mt-24 mt-14">
        <div class="flex-row flex justify-between w-full">
          <h5 class="font-medium pr-10">
            Still Can't decide? You may also like these!
          </h5>
          <div class="flex-row justify-center hidden">
            <button
                :class="currentIndex == 0 ? 'opacity-30' : ''"
                @click="previous()"
                class="px-4 py-2"
            >
              <img src="/images/icons/left-arrow.png"/>
            </button>
            <button
                :class="currentIndex == deals.length - 1 ? 'opacity-30' : ''"
                @click="next()"
                class="pl-4 py-2"
            >
              <img src="/images/icons/right-arrow.png"/>
            </button>
          </div>
        </div>
        <div class="w-full mt-6">
          <carousel v-if="deals.length > 0"
                    class="-mx-3"
                    v-model="currentIndex"
                    :items-to-show="itemsToShow"
          >
            <slide
                v-for="item in deals"
                v-bind:key="item.id"
                class="w-3/12 px-3 pb-8 flex items-start"
            >
              <SearchResultItem
                  class="w-full"
                  :deal="item"
                  :minimal="true"
                  @click.prevent="gotoDetails(item)"
              />
            </slide>
          </carousel>
        </div>
        <div class="flex flex-row justify-center">
          <button
              :class="currentIndex == 0 ? 'opacity-30' : ''"
              @click="previous()"
              class="px-4 py-2"
          >
            <img src="/images/icons/left-arrow.png"/>
          </button>
          <button
              :class="currentIndex == deals.length - 1 ? 'opacity-30' : ''"
              @click="next()"
              class="pl-4 py-2"
          >
            <img src="/images/icons/right-arrow.png"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {Carousel, Slide} from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import {mapGetters} from "vuex";
import DetailsItem from "../components/DetailsItem.vue";
import DetailsListSection from "../components/DetailsListSection.vue";
import DetailsTabSection from "../components/DetailsTabSection.vue";
import SearchResultItem from "../components/SearchResultItem.vue";

export default {
  name: "Details Section",
  components: {
    DetailsItem,
    Carousel,
    Slide,
    SearchResultItem,
    DetailsTabSection,
    DetailsListSection,
  },
  mounted() {
    this.updateItemsToShow();
    this.loadPopular();
    window.addEventListener("resize", this.onResize);
    // this.showSlides();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    // showSlides() {
    //   let i;
    //   let slides = document.getElementsByClassName("autoplay");
    //   let dots = document.getElementsByClassName("index-control");
    //   for (i = 0; i < slides.length; i++) {
    //     slides[i].style.display = "none";
    //   }
    //   this.currentImageIndex++;
    //   if (this.currentImageIndex > slides.length) {
    //     this.currentImageIndex = 1
    //   }
    //   for (i = 0; i < dots.length; i++) {
    //     dots[i].className = dots[i].className.replace(" active", "");
    //   }
    //   slides[this.currentImageIndex - 1].style.display = "block";
    //   dots[this.currentImageIndex - 1].className += " active";
    //   setTimeout(this.showSlides, 2000); // Change image every 2 seconds
    // },
    onResize() {
      this.updateItemsToShow();
    },
    updateItemsToShow() {
      this.itemsToShow = this.isMobile() ? 1.1 : 4;
    },
    isMobile() {
      return window.innerWidth <= 768;
    },
    next() {
      this.currentIndex += this.isMobile() ? 1 : 4;
      if (this.currentIndex > this.deals.length - 1) {
        this.currentIndex = this.deals.length - 1;
      }
    },
    nextGalleryItem() {
      this.currentImageIndex++;
      if (this.currentImageIndex > this.galleryItems.length - 1) {
        this.currentImageIndex = this.galleryItems.length - 1;
      }
      this.scrollToElement("thumbnail_" + this.currentImageIndex);
    },
    previousGalleryItem() {
      this.currentImageIndex--;
      if (this.currentImageIndex < 0) {
        this.currentImageIndex = 0;
      }
      this.scrollToElement("thumbnail_" + this.currentImageIndex);
    },
    previous() {
      this.currentIndex -= this.isMobile() ? 1 : 4;
      if (this.currentIndex < 0) {
        this.currentIndex = 0;
      }
    },
    gotoDetails(item) {
      this.$store.dispatch("setSelectedDealId", item.id);
      this.$router.push({name: "Details", params: {vin: item.id}});
    },
    scrollToElement(className) {
      const el = this.$el.getElementsByClassName(className)[0];
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
      }
    },
    setCurrentImage(index) {
      this.currentImageIndex = index;
      this.scrollToElement("thumbnail_" + index);
    },
    scrollThumbnailRight() {
      const thumbnailsTrack = this.$el.getElementsByClassName("thumbnails")[0];
      thumbnailsTrack.scrollLeft = thumbnailsTrack.scrollLeft + 150;
    },
    scrollThumbnailLeft() {
      const thumbnailsTrack = this.$el.getElementsByClassName("thumbnails")[0];
      thumbnailsTrack.scrollLeft = thumbnailsTrack.scrollLeft - 150;
    },
    loadPopular() {
      axios
          .get(
              `https://m2v98plzh2.execute-api.us-west-2.amazonaws.com/Dev/popular-cars`,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                crossDomain: true,
              }
          )
          .then((res) => {
            let deals = res.data;
            deals = deals.map(function (deal) {
              let formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
              return {
                id: deal.VIN,
                title: deal.year_make_model,
                miles: deal.Odometer,
                bid: formatter.format(deal.purchase_price),
                savings: formatter.format(deal.savings),
                finance_savings: formatter.format(deal.finance_savings),
                bookValue: formatter.format(deal.retail_value),
                image: deal.thumbnail,
                details: deal.car_details,
                car_details: deal.car_details,
                date: deal.bid_final_date,
                currentBid: formatter.format(deal.kbb),
                type: deal.title,
                pdf: deal.pdf,
                series: deal.Series,
                monthly_payments: formatter.format(deal.monthly_payments),
                shipping_fee: deal.shipping_fee,
                down_payment: formatter.format(deal.down_payment),
                sales_tax: deal.sales_tax,
                image_links: deal.image_links,
                image_lengths: deal.image_links,
              };
            });

            this.deals = deals;
          })
          .catch((error) => {
            console.log(error);
            // Manage errors if found any
          });
    },
  },
  computed: {
    ...mapGetters({
      deal: "selectedDeal",
    }),
  },
  watch: {
    deal(newDeal) {
      if (newDeal) {
        let items = newDeal.image_links
        var result = Object.entries(items);
        this.galleryItems = [];
        for (let n = 0; n < result.length; n++) {
          this.galleryItems.push({
            id: n,
            src: result[n][1],
            thumbnail: result[n][1],
          });
        }
      }
    },
  },
  data() {
    return {
      itemsToShow: 4,
      currentIndex: 0,
      slideIndex: '',
      deals: [],
      currentImageIndex: 0,
      galleryItems: [
        {
          id: 1,
          src: "/images/product-gallery/big-1.jpg",
          thumbnail: "/images/product-gallery/big-1.jpg",
        },
        // {
        //   id: 2,
        //   src: "/images/product-gallery/big-2.jpg",
        //   thumbnail: "/images/product-gallery/thumbnail-2.jpg",
        // },
        // {
        //   id: 3,
        //   src: "/images/product-gallery/big-3.jpg",
        //   thumbnail: "/images/product-gallery/thumbnail-3.jpg",
        // },
        // {
        //   id: 4,
        //   src: "/images/product-gallery/big-4.jpg",
        //   thumbnail: "/images/product-gallery/thumbnail-4.jpg",
        // },
        // {
        //   id: 5,
        //   src: "/images/product-gallery/big-5.jpg",
        //   thumbnail: "/images/product-gallery/thumbnail-5.jpg",
        // },
        // {
        //   id: 6,
        //   src: "/images/product-gallery/big-6.jpg",
        //   thumbnail: "/images/product-gallery/thumbnail-6.jpg",
        // },
        // {
        //   id: 7,
        //   src: "/images/product-gallery/big-7.jpg",
        //   thumbnail: "/images/product-gallery/thumbnail-7.jpg",
        // },
        // {
        //   id: 8,
        //   src: "/images/product-gallery/big-8.jpg",
        //   thumbnail: "/images/product-gallery/thumbnail-8.jpg",
        // },
        // {
        //   id: 9,
        //   src: "/images/product-gallery/big-9.jpg",
        //   thumbnail: "/images/product-gallery/thumbnail-9.jpg",
        // },
        // {
        //   id: 10,
        //   src: "/images/product-gallery/big-10.jpg",
        //   thumbnail: "/images/product-gallery/thumbnail-10.jpg",
        // },
      ],
    };
  },
};
</script>
<!--<style>-->
<!--.active {-->
<!--  background-color: #717171;-->
<!--}-->
<!--.autoplay {display: none;}-->
<!--/* Fading animation */-->
<!--.fade {-->
<!--  animation-name: fade;-->
<!--  animation-duration: 1.5s;-->
<!--}-->

<!--@keyframes fade {-->
<!--  from {opacity: .4}-->
<!--  to {opacity: 1}-->
<!--}-->
<!--</style>-->