<template>
  <div class="flex-col flex">
    <!-- Vehicle Details -->
    <div class="w-full border mt-6">
      <VehicleDetails />
    </div>

    <!-- Cost Breakdown -->
    <div class="w-full border mt-6">
      <CostBreakdownMobile />
    </div>

    <!-- Timeline -->
    <div class="w-full border mt-6">
      <Timeline />
    </div>
  </div>
</template>
<script>
import VehicleDetails from './VehicleDetails.vue';
import CostBreakdownMobile from './CostBreakdownMobile.vue';
import Timeline from './Timeline.vue';
export default {
  name: 'Details Section',
  components: {
    VehicleDetails,
    CostBreakdownMobile,
    Timeline,
  },
  methods: {},
  data() {
    return {
      currentView: 1,
    };
  },
};
</script>
