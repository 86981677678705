<!--<template>-->
<!--  <div class="">-->
<!--    <div v-if="isLoading"-->
<!--         class="fixed top-0 left-0 bottom-0 right-0 bg-white bg-opacity-90 flex justify-center items-center-->
<!--      z-100">-->
<!--      <img width="350"-->
<!--           height="auto"-->
<!--           src="/images/icons/fairpy-loader.gif"-->
<!--           alt="Fairpy Loading"/>-->
<!--    </div>-->
<!--    <div class="flex flex-col p-1 border bg-white transition-all shadow-lg-dark hover:shadow-xl relative">-->
<!--      <div class="w-full lg:h-28 h-28 relative flex-shrink-0">-->
<!--        <div class="absolute top-2 left-2 z-50">-->
<!--          <p class="rounded-sm px-2 py-1 text-white bg-blue text-xs bg-opacity-90">Sample Photo</p>-->
<!--        </div>-->
<!--        <img class="absolute object-contain w-full h-full"-->
<!--             :src="deal.image"/>-->
<!--      </div>-->
<!--      <div class="flex flex-col p-4 text-black-light">-->
<!--        <div class="flex flex-row justify-between items-start">-->
<!--          <h2 :title="deal.title"-->
<!--              class="font-semibold text-left text-base no whitespace-nowrap overflow-hidden overflow-ellipsis">-->
<!--            {{ deal.title }}-->
<!--          </h2>-->
<!--          <i :class="isFavourite ? 'selected' : ''"-->
<!--             class="icon md wishlist cursor-pointer flex-shrink-0 flex-grow-0"-->
<!--             @click.stop="setFavourite()"></i>-->
<!--        </div>-->
<!--        <p class="text-sm text-black-lighter text-left overflow-ellipsis whitespace-nowrap overflow-hidden">-->
<!--          {{ deal.series }}<span v-if="!deal.series"-->
<!--                                 class="text-transparent">SERIES</span>-->
<!--        </p>-->
<!--        <div class="flex flex-row justify-start items-center text-black-lighter my-2">-->
<!--          <div class="flex flex-row items-center py-2 pt-1 text-sm mr-6">-->
<!--            <i class="icon small miles mr-2"></i> {{ deal.miles }} miles-->
<!--          </div>-->
<!--          <div class="flex flex-row items-center py-2 pt-1 text-sm capitalize">-->
<!--            <i class="icon small title mr-2"></i> {{ deal.type }}-->
<!--          </div>-->
<!--        </div>-->
<!--        <hr v-if="!minimal"/>-->
<!--        <div v-if="!minimal"-->
<!--             class="flex py-4 flex-col text-xs text-black-lighter">-->
<!--          <p class="mb-2 capitalize">Car Details: {{ deal.details }}</p>-->
<!--&lt;!&ndash;          <p v-if="!isFinance()"&ndash;&gt;-->
<!--&lt;!&ndash;             class="mb-2">Bid Final Date : {{ deal.date }}</p>&ndash;&gt;-->
<!--          <p v-if="!isFinance()"-->
<!--             class="mb-2">Offer Valid Until : {{ this.offerValidDate }}</p>-->
<!--          <p v-if="isFinance()"-->
<!--             class="mb-2">Down Payment: ${{ deal.down_payment.toLocaleString('en-US', {maximumFractionDigits: 0}) }}-->
<!--          </p>-->
<!--          &lt;!&ndash;          <p>Shipping Cost: ${{ // deal.shipping_fee.toLocaleString() }}</p>&ndash;&gt;-->
<!--          <p>Car arrives: {{ getNextDate(14) }}</p>-->
<!--        </div>-->
<!--        <hr/>-->

<!--        <div class="flex flex-row mt-3 items-center justify-between">-->
<!--          <div v-if="!isFinance()"-->
<!--               class="text-blue leading-3 relative flex-grow mr-4 text-left">-->
<!--            <p class="font-semibold lg:text-xl text-2xl">${{ deal.bid.toLocaleString() }}</p>-->
<!--            <span class="text-xs mt-1 text-black-lighter" style="vertical-align: sub">Purchase Price</span>-->
<!--          </div>-->
<!--          <div v-if="isFinance()"-->
<!--               class="text-blue leading-3 relative flex-grow mr-4 text-left">-->
<!--            <p v-if="deal.monthly_payments"  class="text-sm">-->
<!--            <span class="font-semibold lg:text-xl text-2xl">${{ deal.monthly_payments.toLocaleString('en-US', {maximumFractionDigits: 0}) }}</span> /mo  </p>-->
<!--            <p v-if="!deal.monthly_payments"-->
<!--               class="font-semibold lg:text-xl text-2xl">-</p>-->
<!--&lt;!&ndash;            <span class="text-xs mt-1 text-black-light">Per Month</span>&ndash;&gt;-->
<!--            <div class="text-xs mt-1 text-black-light">(48 Months)</div>-->
<!--          </div>-->
<!--          <div class="text-right leading-3">-->
<!--            <p class="font-normal text-lg">-->
<!--              <span  v-if="isFinance()"> ${{ deal.finance_savings.toLocaleString() }}</span>-->
<!--              <span  v-if="!isFinance()"> ${{ deal.savings.toLocaleString() }}</span>-->
<!--            </p>-->

<!--            <div class="text-black-lighter cursor-pointer flex flex-row lg:hidden items-center text-xs mt-1"-->
<!--                 @click.stop="toggleKelly">-->
<!--              Savings*-->
<!--              <i class="icon small info ml-1"></i>-->

<!--              <div :class="showKelly ? 'block' : 'hidden'"-->
<!--                   class="absolute bottom-4 right-4 left-4 rounded-sm border bg-gray-lighter bg-opacity-95">-->
<!--                <div class="w-full relative">-->
<!--                  <div-->
<!--                      class="h-full text-sm border-2 border-black-light rounded p-5 flex flex-col shadow-lg-dark justify-center items-center">-->
<!--                    <img src="/images/icons/badge.png"-->
<!--                         class="mx-auto w-20"/>-->
<!--                    <div class="flex flex-row justify-between mx-auto mb-4 mt-4 w-full">-->
<!--                      <div class="text-black-lighter">Retail Value</div>-->
<!--                      <div class="ml-4 font-medium">${{ deal.bookValue.toLocaleString() }}</div>-->
<!--                    </div>-->
<!--                    <div class="flex flex-row justify-between mx-auto mb-5 w-full">-->
<!--                      <div class="text-black-lighter">Cost of Car</div>-->
<!--                      <div class="ml-4 font-medium">${{ deal.bid.toLocaleString() }}</div>-->
<!--                    </div>-->
<!--                    <div class="flex flex-row justify-between mx-auto mb-5 w-full">-->
<!--                      <div class="text-black-lighter">Sales Tax</div>-->
<!--                      <div class="ml-4 font-medium">${{ deal.sales_tax.toLocaleString() }}</div>-->
<!--                    </div>-->
<!--                    <div class="bg-black-light w-full text-center font-medium text-base text-white px-5 py-3 rounded">-->
<!--                      <span class="">You Saved</span>-->
<!--                      <span  v-if="isFinance()"> ${{ deal.finance_savings.toLocaleString() }}</span>-->
<!--                      <span  v-if="!isFinance()"> ${{ deal.savings.toLocaleString() }}</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <i class="fa fa-times absolute right-3 top-3 text-black-lighter"></i>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash; Remove Duplicate &ndash;&gt;-->
<!--            <div class="text-black-lighter cursor-pointer lg:flex flex-row hidden items-center text-xs mt-1"-->
<!--                 @mouseenter="showKelly = true"-->
<!--                 @mouseleave="showKelly = false">-->
<!--              Savings*-->
<!--              <i class="icon small info ml-1"></i>-->

<!--              <div :class="showKelly ? 'block' : 'hidden'"-->
<!--                   class="absolute bottom-4 right-4 left-4 rounded-sm border bg-gray-lighter bg-opacity-95">-->
<!--                <div class="w-full relative">-->
<!--                  <div-->
<!--                      class="h-full text-sm border-2 border-black-light rounded p-5 flex flex-col shadow-lg-dark justify-center items-center">-->
<!--                    <img src="/images/icons/badge.png"-->
<!--                         class="mx-auto w-20"/>-->
<!--                    <div class="flex flex-row justify-between mx-auto mb-4 mt-4 w-full">-->
<!--                      <div class="text-black-lighter">Retail Value</div>-->
<!--                      <div class="ml-4 font-medium">${{ deal.bookValue.toLocaleString() }}</div>-->
<!--                    </div>-->
<!--                    <div class="flex flex-row justify-between mx-auto mb-5 w-full">-->
<!--                      <div class="text-black-lighter">Cost of Car</div>-->
<!--                      <div class="ml-4 font-medium">${{ deal.bid.toLocaleString() }}</div>-->
<!--                    </div>-->
<!--                    <div class="flex flex-row justify-between mx-auto mb-5 w-full">-->
<!--                      <div class="text-black-lighter">Sales Tax</div>-->
<!--                      <div class="ml-4 font-medium">${{ deal.sales_tax.toLocaleString() }}</div>-->
<!--                    </div>-->
<!--                    <div class="bg-black-light w-full text-center font-medium text-base text-white px-5 py-3 rounded">-->
<!--                      <span class="">You Saved</span>-->
<!--                      <span  v-if="isFinance()"> ${{ deal.finance_savings.toLocaleString() }}</span>-->
<!--                      <span  v-if="!isFinance()"> ${{ deal.savings.toLocaleString() }}</span>-->

<!--                    </div>-->
<!--                  </div>-->
<!--                  <i class="fa fa-times absolute right-3 top-3 text-black-lighter"></i>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->


<template>
  <div class="">
    <div v-if="isLoading"
         class="fixed top-0 left-0 bottom-0 right-0 bg-white bg-opacity-90 flex justify-center items-center
      z-100">
      <img width="350"
           height="auto"
           src="/images/icons/fairpy-loader.gif"
           alt="Fairpy Loading"/>
    </div>

    <div class="shadow" style="cursor: pointer">
      <div class="car-image relative" style="width: 100%; height: 170px">
        <img class="object-contain" :src="deal.image" alt="" style="width: 100%; height: 170px;">
      </div>

      <div class="p-4">
        <div class="flex flex-row justify-between items-start">
          <h6 class="py-1 tracking-wider text-center uppercase text-blue tiempos text-xs"> {{ deal.title }}</h6>

          <i :class="isFavourite ? 'selected' : ''"
             class="icon md wishlist cursor-pointer flex-shrink-0 flex-grow-0 whitespace-nowrap overflow-hidden overflow-ellipsis"
             @click.stop="setFavourite()"></i>
        </div>

        <div class="flex justify-between mb-2 mt-3">
          <div>
            <h6 class="pb-1 font-size-12 text-base text-gray-500 uppercase tiempos">{{ deal.miles }}</h6>
            <p class="text-xs font-size-9 tracking-wider text-gray-400 uppercase calibre">MILES</p>
          </div>
          <div>
            <h6 class="pb-1 font-size-12 text-base text-center text-gray-500 uppercase tiempos">{{ deal.savings }}*</h6>
            <p class="text-xs font-size-9 tracking-wider text-center text-gray-400 uppercase calibre">
              SAVINGS
            </p>
          </div>
          <div>
            <h6 class="pb-1 font-size-12 text-base text-right text-gray-500 tiempos">
              {{ deal.car_details }}</h6>
            <p class="text-xs font-size-9 tracking-wider text-right text-gray-400 uppercase calibre">
              CAR DETAILS</p>
          </div>
        </div>
        <div class="md:flex lg:flex flex grid grid-cols-3 gap-3 p-2 border border-black justify-between"
             style="border: 1px solid #374151;">
          <div class="text-left text-center">
            <h6 class="pb-1 font-size-14 text-base uppercase text-sky-500 tiempos">
              {{ deal.bid }}</h6>
            <p class="text-xs fa-w-4  tracking-wider text-black calibre text-center">
              List Price </p>
          </div>
          <div class="text-left text-center">
            <h6 class="pb-1 font-size-14 text-base uppercase text-sky-500 tiempos">
              {{ deal.monthly_payments }}*</h6>
            <p class="text-xs fa-w-4 tracking-wider text-black  calibre">
              Per Month</p>
          </div>
          <div class="text-left text-center">
            <h6 class="pb-1 font-size-14 text-base uppercase text-sky-500 tiempos">
              {{ deal.down_payment }}</h6>
            <p class="text-xs  fa-w-4  tracking-wider text-black  calibre">
              Cash Down</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import {useToast} from "vue-toastification";
import {mapGetters} from "vuex";

export default {
  props: {
    deal: Object,
    minimal: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "searchParams",
    }),
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    this.getAddDays();
  },
  data() {
    return {
      showKelly: false,
      isFavourite: false,
      isLoading: false,
      offerValidDate: ''
    };

  },
  methods: {
    getAddDays() {
      var options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
      };
      const validDate = new Date();
      validDate.setDate(validDate.getDate() + 2);
      let validDateFormat = validDate.toLocaleDateString("en-US", options);
      let validFormat = validDateFormat.split(" ");
      let validFormatDate = validFormat[1].replace(/,/g, '') + '-' + validFormat[0] + '-' + validFormat[2];
      this.offerValidDate = validFormatDate;
    },
    getNextDate(daysToAdd = 0) {
      var options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
      };
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0');
      let yy = today.getFullYear();


      today = mm + '-' + dd + '-' + yy;

      this.date = today;

      if (this.deal.date) {
        today = this.deal.date;
      }
      if (today && Date.parse(today)) {
        const date = new Date(Date.parse(today));

        const newDate = new Date(Date.parse(date.toDateString()));
        newDate.setDate(date.getDate() + daysToAdd);
        let getDate = newDate.toLocaleDateString("en-US", options);
        let dateParts = getDate.split(" ");
        let formatDate = dateParts[1].replace(/,/g, '') + '-' + dateParts[0] + '-' + dateParts[2];
        return formatDate;
      }
    },
    isFinance() {
      return !this.searchParams.is_price;
    },
    toggleKelly() {
      this.showKelly = !this.showKelly;
    },
    setFavourite() {
      if (!this.loggedIn) {
        this.emitter.emit("showLogin", true);
        return;
      }

      this.isFavourite = !this.isFavourite;
      if (this.isFavourite) {
        this.updateFavouriteToServer();
        this.wishlistCars();
      }
    },
    updateFavouriteToServer() {
      this.$store
          .dispatch("setFavourite", this.deal.id)
          .then((res) => {
            console.log(res);
              // this.toast.success("Added to Wishlist Successfully");
          })
          .catch((error) => {
            console.log(error);
            // this.toast.success("Sorry Unable to add to wishlist");
          });
    },

    wishlistCars() {
      const params = {...this.deal};
      this.$store
          .dispatch("setWishlistCars", params)
          .then((res) => {

            if (res.data.status == 1) {
              this.toast.success("Added to Wishlist Successfully");
            } else {
              this.toast.error("Already add your wish list cars");
            }
          })
          .catch((error) => {
            console.log(error);
            // this.toast.error("Sorry Unable to add to wishlist");
          });
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 768px) {
  .object-custom-position {
    object-position: 0px -100px !important;
  }
}

.object-custom-position {
  object-position: 0px 0px;
}
</style>