<template>
  <TopBar />
  <NavBar />
  <div class="hidden">
    <SearchBanner
      :class="['lg:details-banner-bg', 'details-banner-mobile-bg']"
    />
  </div>
  <DetailsSection />
  <Footer />
</template>

<script>
import TopBar from "../components/TopBar.vue";
import NavBar from "../components/NavBar.vue";
import Footer from "../components/Footer.vue";
import SearchBanner from "../components/SearchBanner.vue";
import DetailsSection from "../components/DetailsSection.vue";

export default {
  name: "Home",
  components: {
    TopBar,
    NavBar,
    Footer,
    SearchBanner,
    DetailsSection,
  },
  methods: {
    loadSelectedDetails() {
      this.$store.dispatch("fetchSelectedDeal", this.$route.params.vin);
    },
  },
  watch: {
    $route() {
      this.loadSelectedDetails();
    },
  },
  mounted() {
    this.loadSelectedDetails();
  },
};
</script>

<style></style>
