<template>
  <div class="lg:hidden flex flex-col items-stretch">
    <h5 class="text-lg font-medium p-3">Vehicle Details</h5>
    <hr class="mx-3" />
  </div>
  <div
  v-if="deal"
    class="flex lg:flex-row flex-wrap justify-between px-5 lg:pt-14 mt-14 w-full"  >
    <div
      v-for="(item, index) in [0, 1, 2, 3, 4]"
      :key="index"
      class="flex lg:flex-col flex-row lg:w-auto w-full">
      <div v-if="details[item]" class="flex flex-col lg:mb-28 mb-14 flex-grow lg:w-auto w-1/2">
        <div class="flex flex-row text-gray-medium text-sm items-end capitalize">
          <i :class="[details[item].icon]" class="icon regular mr-2"></i>
          {{ details[item].title }}
        </div>
        <div v-if="containsSee(details[item].text)" class="mt-3 capitalize underline">
          <a :href="deal.pdf" target="_blank">{{details[item].text}}</a>
        </div>
        <div v-if="!containsSee(details[item].text)" class="mt-3 capitalize">{{ details[item].text }}</div>
      </div>
      <div v-if="details[item]" class="flex flex-col lg:mb-28 mb-14 lg:w-auto w-1/2">
        <div class="flex flex-row text-gray-medium text-sm items-end capitalize">
          <i :class="[details[item + 5].icon]" class="icon regular mr-2"></i>
          {{ details[item + 5].title }}
        </div>
        <div v-if="containsSee(details[item + 5].text)" class="mt-3 capitalize underline"><a :href="deal.pdf" target="_blank">{{details[item + 5].text}}</a></div>
        <div v-if="!containsSee(details[item + 5].text)" class="mt-3 capitalize">{{ details[item + 5].text }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Vehicle Details Tab",
  components: {},
  methods: {
    containsSee(value) {
      return typeof value === "string"
        ? value.toLowerCase().indexOf("see") > -1
        : false;
    },
    loadSelectedDetails() {
      this.$store.dispatch("fetchSelectedDeal");
    },
    fillDealDetails(deal) {
      this.details = [
        {
          icon: "color",
          title: "Color",
          text: deal.exterior,
        },
        {
          icon: "engine",
          title: "Engine",
          text: `${deal.engine}`,
        },
        {
          icon: "drive",
          title: "Drive",
          text: deal.drive,
        },
        {
          icon: "transmission",
          title: "Transmission",
          text: deal.transmission,
        },
        {
          icon: "fuel-type",
          title: "Fuel Type",
          text: deal.fuel_type,
        },
        {
          icon: "cylinders",
          title: "Cylinders",
          text: deal.cylinders,
        },
        {
          icon: "location-details",
          title: "Location",
          text: deal.location,
        },
        {
          icon: "title-details",
          title: "Title",
          text: deal.type,
        },
        {
          icon: "runs",
          title: "Runs/Drives",
          text: deal.verification,
        },
        {
          icon: "damage",
          title: "Car Condition",
          text: deal.car_details,
        },
      ];
    },
  },
  computed: {
    ...mapGetters({
      deal: "selectedDeal",
    }),
  },
  watch: {
    deal(newDeal) {
      if (newDeal) {
        this.fillDealDetails(newDeal);
      }
    },
  },
  mounted() {
    // this.loadSelectedDetails();
  },
  data() {
    return {
      details: [],
    };
  },
};
</script>
