<template lang="">
  <div class="lg:hidden flex flex-col items-stretch">
    <h5 class="text-lg font-medium p-3">Timeline</h5>
    <hr class="mx-3" />
  </div>
  <div v-if="deal" class="flex flex-row items-stretch bg-white">
    <div
      class="flex flex-grow relative lg:flex-row flex-col items-start lg:items-center justify-between lg:px-8 lg:pt-14 lg:mt-14 lg:mb-14 pl-4 py-5 bg-white"
    >
      <div
        class="absolute lg:flex hidden items-center flex-col justify-center left-20 right-20 bottom-5 top-0 z-0 opacity-10"
      >
        <div class="h-28 border-t border-b border-gray-700 relative w-full">
          <svg
            width="100%"
            preserveAspectRatio="none"
            class="top-1/2 absolute h-2 w-full text-gray-700"
            viewBox="0 0 30 10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <!-- Dashes and gaps of the same size -->
            <line
              x1="0"
              y1="0"
              x2="30"
              y2="0"
              stroke-width="5"
              stroke="currentColor"
              stroke-dasharray="0.3 0.3"
            />
          </svg>
        </div>
      </div>

      <div
        class="lg:flex-col flex-row flex items-center mb-4 lg:mb-0 relative z-10"
      >
        <img
          class="lg:w-32 lg:h-32 w-24 h-24 lg:mr-0 mr-5"
          src="/images/icons/auction-date.png"
        />
        <div class="flex-col flex lg:items-center">
          <div class="text-black-lighter text-sm mt-2 mb-2">Reserve Date</div>
          <div class="text-black-light font-medium">{{getNextDate()}}</div>
        </div>
      </div>

      <div
        class="lg:flex-col flex-row flex items-center mb-4 lg:mb-0 relative z-10"
      >
        <img
          class="lg:w-32 lg:h-32 w-24 h-24 lg:mr-0 mr-5"
          src="/images/icons/delivery-date.png"
        />
        <div class="flex-col flex lg:items-center">
          <div class="text-black-lighter text-sm mt-2 mb-2">Delivery Date</div>
          <div class="text-black-light font-medium">{{getNextDate(10)}}</div>
        </div>
      </div>

      <div
        class="lg:flex-col flex-row flex items-center mb-4 lg:mb-0 relative z-10"
      >
        <img
          class="lg:w-32 lg:h-32 w-24 h-24 lg:mr-0 mr-5"
          src="/images/icons/return-max.png"
        />
        <div class="flex-col flex lg:items-center">
          <div class="text-black-lighter text-sm mt-2 mb-2">
            Return Date Max
          </div>
          <div class="text-black-light font-medium">{{getNextDate(17)}}</div>
        </div>
      </div>

      <div
        class="lg:flex-col flex-row flex items-center mb-4 lg:mb-0 relative z-10"
      >
        <img
          class="lg:w-32 lg:h-32 w-24 h-24 lg:mr-0 mr-5"
          src="/images/icons/title-delivery.png"
        />
        <div class="flex-col flex lg:items-center">
          <div class="text-black-lighter text-sm mt-2 mb-2">Title Delivery</div>
          <div class="text-black-light font-medium">{{getNextDate(28)}}</div>
        </div>
      </div>
    </div>
    <div class="lg:hidden flex w-20 relative">
      <div
        class="absolute flex items-center flex-col justify-center w-16 right-3 bottom-10 top-5 z-0 opacity-20"
      >
        <div
          class="h-full border-l border-r border-gray-medium relative w-full"
        >
          <svg
            height="100%"
            preserveAspectRatio="none"
            stroke-width="3"
            class="left-1/2 absolute w-2 text-gray-medium"
            viewBox="0 0 10 30"
            xmlns="http://www.w3.org/2000/svg"
          >
            <!-- Dashes and gaps of the same size -->
            <line
              x1="0"
              y1="0"
              x2="0"
              y2="30"
              stroke="currentColor"
              stroke-dasharray="0.7 0.7"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Timeline Tab",
  components: {},
  methods: {
    loadSelectedDetails() {
      this.$store.dispatch("fetchSelectedDeal");
    },
    getNextDate(daysToAdd = 0) {
      var options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      if (this.deal.auction_date && Date.parse(this.deal.auction_date)) {
        const date = new Date(Date.parse(this.deal.auction_date));
        const newDate = new Date(Date.parse(date.toLocaleDateString()));
        newDate.setDate(date.getDate() + daysToAdd);
        return newDate.toLocaleDateString("en-US", options);
      } else {
        return this.deal.auction_date;
      }
    },
  },
  computed: {
    ...mapGetters({
      deal: "selectedDeal",
    }),
  },
  watch: {},
  mounted() {
    // this.loadSelectedDetails();
  },
};
</script>
