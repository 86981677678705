<template>
  <div class="">
    <div v-if="isLoading" class=" fixed top-0 left-0 bottom-0 right-0 bg-white bg-opacity-90
      flex  justify-center items-center z-100">
      <img width="350" height="auto" src="/images/icons/fairpy-loader.gif" alt="Fairpy Loading"/>
    </div>
    <div v-if="deal" class="flex flex-col border bg-white transition-all relative p-5 text-black-light">
      <div class="flex flex-row justify-between items-start">
        <h2 class="font-semibold text-left text-lg no pr-6">
          {{ deal.title }}
        </h2>
        <i :class="isFavourite ? 'selected' : ''"
           class="icon wishlist cursor-pointer flex-shrink-0 flex-grow-0"
           @click="setFavourite"></i>
      </div>
      <p class="text-sm mb-2 text-black-lighter text-left overflow-ellipsis whitespace-nowrap overflow-hidden">
        {{ deal.series }}
      </p>
      <div class="flex flex-row justify-start items-center text-black-lighter lg:my-4 my-2">
        <div class="flex flex-row items-center py-2 pt-1 text-sm mr-6">
          <i class="icon small miles mr-2"></i> {{ deal.miles }} miles
        </div>
        <div class="flex flex-row items-center py-2 pt-1 text-sm">
          <i class="icon small title mr-2 capitalize"></i> {{ deal.type }}
        </div>
      </div>
      <div class="flex flex-row mb-3 text-xs text-gray-medium">
        <i class="icon map gray small mr-1"></i>
        <span>Delivery to 73301</span>
        <i class="cursor-pointer lg:ml-1 ml-auto text-black-lighter font-semibold">
          Change Location
        </i>
      </div>
      <hr/>
      <div class="hidden lg:flex py-4 mt-2 flex-col text-xs text-black-lighter">
        <div class="mb-3 flex">
          <span class="w-1/2">Car Details</span>
          <span class="w-1/2 capitalize">{{ deal.details }}</span>
        </div>
        <div v-if="this.paymentType" class="mb-3 flex">
          <!--          <span class="w-1/2">Bid Final Date</span>-->
          <span class="w-1/2">Offer Valid Until</span>
          <span class="w-1/2">{{ this.offerValidDate }}</span>
        </div>
        <div v-if="!this.paymentType" class="mb-3 flex">
          <span class="w-1/2">Down Payment</span>
          <span class="w-1/2">${{ deal.down_payment.toLocaleString('en-US', {maximumFractionDigits: 0}) }}</span>
        </div>
        <!--        <div class="mb-3 flex font-semibold">-->
        <!--          <span class="w-1/2">Shipping Cost</span>-->
        <!--          <span class="w-1/2">${{ deal.shipping_fee.toLocaleString() }}</span>-->
        <!--        </div>-->

        <div class="mb-3 flex">
          <span class="w-1/2">Car arrives</span>
          <span class="w-1/2">{{ this.arrivalDate }}</span>
        </div>
      </div>
      <div class="lg:bg-gray-lighter lg:p-3 mt-3 lg:mt-0 mb-3">

        <div class="rounded-full bg-white w-full p-1 flex items-center mb-2 mt-4 relative shadow">
          <div class="absolute w-1/2 bottom-0 top-0 p-1 transition-all flex"
               :class="{ 'left-1/2': this.paymentType, 'left-0': !this.paymentType}">
            <div class="bg-blue rounded-full shadow-sm w-full h-full"></div>
          </div>
          <div @click="togglePayment"
               class="w-1/2 text-center py-2 text-sm relative cursor-pointer"
               :class="{ 'text-white': !this.paymentType }">
            Finance
          </div>
          <div @click="togglePayment"
               class="py-2 w-1/2 text-sm text-center relative cursor-pointer"
               :class="{ 'text-white': this.paymentType }">
            Cash
          </div>
        </div>

        <div class="flex flex-row items-center justify-between">
          <div v-if="this.paymentType" class="text-blue leading-3 relative flex-grow">
            <p class="font-semibold lg:text-2.5xl text-2xl">${{ deal.bid.toLocaleString() }}</p>
            <!--            <span class="text-sm mt-1">Fair Bid</span>-->
            <span class="text-sm mt-1 text-black-lighter ">Purchase Price</span>
          </div>
          <div v-if="!this.paymentType" class="text-blue leading-3 relative flex-grow">
            <p class="text-sm">
              <span class="font-semibold lg:text-2.5xl text-2xl">${{
                  deal.monthly_payments.toLocaleString('en-US', {maximumFractionDigits: 0})
                }}</span> /mo</p>
            <span class="text-sm mt-1">(48 Months)</span>
          </div>
          <div class="text-right leading-3">
            <p class="font-normal text-lg">
              <span v-if="this.paymentType">${{ deal.savings.toLocaleString() }}</span>
              <span v-if="!this.paymentType">${{ deal.finance_savings.toLocaleString() }}</span>
            </p>
            <div class="text-black-lighter cursor-pointer flex flex-row items-center text-sm">
              Savings*
            </div>
          </div>
        </div>
        <div class="bg-gray-light p-1 mt-3 text-xs text-center">
          Retail Value: ${{ deal.bookValue.toLocaleString() }}*
        </div>
      </div>
      <div class="flex lg:hidden py-2 mt-2 flex-col text-xs text-black-lighter">
        <div class="mb-3 flex">
          <span class="w-1/2">Car Details</span>
          <span class="w-1/2 capitalize">{{ deal.details }}</span>
        </div>
        <div v-if="this.paymentType" class="mb-3 flex">
          <!--          <span class="w-1/2">Bid Final Date</span>-->
          <span class="w-1/2">Offer Valid Until</span>
          <span class="w-1/2">{{ this.offerValidDate }}</span>
        </div>
        <div v-if="!this.paymentType" class="mb-3 flex">
          <span class="w-1/2">Down Payment</span>
          <span class="w-1/2">${{ deal.down_payment.toLocaleString('en-US', {maximumFractionDigits: 0}) }}</span>
        </div>
        <!--        <div class="mb-3 flex font-semibold">-->
        <!--          <span class="w-1/2">Shipping Cost</span>-->
        <!--          <span class="w-1/2">${{ deal.shipping_fee.toLocaleString() }}</span>-->
        <!--        </div>-->
        <div class="mb-3 flex">
          <span class="w-1/2">Car arrives</span>
          <span class="w-1/2">{{ this.arrivalDate }}</span>
        </div>
      </div>
      <div class="lg:flex flex-col mt-3 mb-5 hidden">
        <!--        <div v-if="this.paymentType" class="border h-11 border-black-light flex flex-row items-center">-->
        <!--          <button @click="decrement()"-->
        <!--                  type="button"-->
        <!--                  class="px-3 py-3 flex disable-doubletap-to-zoom">-->
        <!--            <i class="icon minus small"></i>-->
        <!--          </button>-->
        <!--          <span-->
        <!--              class="text-base text-center flex-grow border-transparent focus:border-transparent focus:ring-0 focus:outline-none min-w-0">-->
        <!--${{ yourBid }}-->
        <!--            $<input v-model.number="yourBid"-->
        <!--                  @keypress="isNumber($event)"-->
        <!--                        class="text-base flex-grow border-transparent focus:border-transparent focus:ring-0 focus:outline-none min-w-0"/>-->
        <!--          </span>-->
        <!--          <button @click="increment()"-->
        <!--                  type="button"-->
        <!--                  class="px-3 py-3 flex disable-doubletap-to-zoom">-->
        <!--            <i class="icon plus small"></i>-->
        <!--          </button>-->
        <!--        </div>-->
        <button @click.prevent="generalEnquiry"
                class=" mt-2 bg-blue uppercase text-sm text-white h-11 w-full lg:block hidden">
          Get More Info
        </button>

        <button v-if="this.paymentType" v-on:click="trackEvent"
                type="button"
                @click.prevent="showBidModal"
                class="bg-blue text-white w-full h-11 text-sm uppercase mt-6">
          Reserve Car
        </button>
        <button v-if="!this.paymentType"
                type="button"
                @click.prevent="showFinanceModal"
                class="bg-blue text-white w-full h-11 text-sm uppercase mt-6">
          Get Finance
        </button>


        <p style="font-size: 11px" class="text-sm mt-5 mb-3 text-center">Don’t like the car no worries you can return
          back the car to us for full refund <span style="color: red">*</span></p>
      </div>
    </div>
  </div>

  <div class="lg:hidden bg-white flex flex-row shadow-top-shadow z-50 items-center p-3 fixed bottom-0 right-0 left-0">
    <!--    <div v-if="this.paymentType" class="border h-11 border-black-light flex flex-grow flex-row items-center">-->
    <!--      <button @click="decrement()"-->
    <!--              type="button"-->
    <!--              class="px-3 py-3 flex disable-doubletap-to-zoom">-->
    <!--        <i class="icon minus small"></i>-->
    <!--      </button>-->
    <!--      <div>$</div>-->
    <!--      <input v-model.number="yourBid"-->
    <!--             @keypress="isNumber($event)"-->
    <!--             class="text-base border-transparent focus:border-transparent focus:ring-0 focus:outline-none min-w-0 w-full"/>-->
    <!--      <button @click="increment()"-->
    <!--              type="button"-->
    <!--              class="px-3 py-3 flex disable-doubletap-to-zoom">-->
    <!--        <i class="icon plus small"></i>-->
    <!--      </button>-->
    <!--    </div>-->
    <div class="flex flex-row">
      <button v-if="this.paymentType"
              type="button"
              @click.prevent="showBidModal"
              class="bg-blue px-5 w-1/2 ml-1 text-white h-11 flex-shrink-0 text-sm uppercase">
        Reserve Car
      </button>

      <button v-if="this.paymentType"
              type="button"
              @click.prevent="generalEnquiry"
              class="bg-blue px-5 w-1/2 ml-3 text-white h-11 flex-shrink-0 text-sm uppercase">
        Get More Info
      </button>
      <!--      <button @click.prevent="generalEnquiry" type="button"-->
      <!--              class=" mt-6 bg-blue uppercase text-white h-11 flex-shrink-0 w-full lg:block hidden">-->
      <!--        Get More Info-->
      <!--      </button>-->

      <button v-if="!this.paymentType"
              type="button"
              @click.prevent="showFinanceModal"
              class="bg-blue w-full text-white h-11 flex-shrink-0 text-sm uppercase mr-3">
        Get Finance
      </button>

      <button type="button"
              @click.prevent="generalEnquiry"
              class="bg-blue w-full text-white h-11 flex-shrink-0 text-sm uppercase">
        Get More Info
      </button>

      <!--      <button-->
      <!--          @click.prevent="generalEnquiry"-->
      <!--          class="bg-blue uppercase text-white p-4 w-full lg:hidden block"-->
      <!--      >-->
      <!--        Get More Info-->
      <!--      </button>-->

    </div>
  </div>

  <Modal bodyClass="lg:w-md-modal w-screen lg:h-auto justify-center m-auto p-5 min-h-screen"
         v-show="isModalVisible"
         @close="closeModal">
    <template v-slot:header>
      <h1 v-if="!isOtpModalVisible"
          class="lg:text-3xl text-2xl font-display mt-2 lg:mb-10 mb-5 text-center">
        Just Pay 10% Now!<br>Pay the balance Later
      </h1>
      <h1 v-if="isOtpModalVisible"
          class="
      text-xl
      font-display
      lg:font-medium
      font-semibold
      mt-10
      mb-4
      text-center
    ">
        OTP Verification
      </h1>
    </template>

    <template v-slot:body>
      <div v-if="!isOtpModalVisible" class="flex justify-center relative w-full">
        <div
            class="
        w-full
        flex flex-col
        justify-center
        items-center
        text-center
        relative
      "
        >
          <div class="lg:w-2/3 w-10/12 flex flex-col">
            <img width="" src="/images/bid-header-1.png"/>
          </div>
          <!--          <p class="text-sm text-gray-medium lg:px-4 px-0 mt-6 mb-6">-->
          <!--            FairPY blocks 10% of your Bid Value to process the auction for your-->
          <!--            vehicle.<br/>You can pay the Balance 90% of the Bid amount at the time-->
          <!--            of your vehicle delivery. Feel a smooth and hassle-free experience!-->
          <!--          </p>-->

          <p class="text-sm text-gray-medium text-left lg:px-4 px-0 mt-6 mb-2">
            To reserve the car, we put a hold on your credit card for 10% of the purchase price or down payment. We will
            let you know the status of your purchase within the next 24 to 48 hours.
          </p>
          <p class="text-sm text-gray-medium text-left lg:px-4 px-0  mb-6">
            If we are able to procure the car
            from our wholesale partner we ask you to wire the down payment and thereafter we release your credit card
            hold and start the shipping process. If we are unsuccessful in procuring the car from our wholesale partners
            we release the credit card hold immediately.
          </p>
          <p class="lg:text-xl text-base font-medium">
            10% of down payment or purchase price: <span v-if="beforeDiscount">${{ bidBookValue() }}</span>
            <span v-if="afterDiscount" class="text-xs mr-2 text-red-600"><s>${{ bidBookValue() }}</s></span>
            <span v-if="afterDiscount" class="text-lg font-bold">${{ this.afterDiscountAmount.toLocaleString() }}</span>
          </p>

          <div class="p-6 pt-4 lg:px-16 px-0 w-full">
            <div class="md:bg-gray-light xl:bg-gray-light lg:bg-gray-light 2xl::bg-gray-light
            flex flex-col items-center px-0 lg:px-20 py-4 lg:mb-0 mb-6">

              <div class="w-full mb-6 bg-white rounded-lg">


                <div class="flex flex-row justify-between">
                  <div class="text-left p-2">
                    <div class="flex flex-col text-center">
                      <div class="text-3xl flex-row font-semibold space-x-2">PROMO CODE</div>
                      <div class="text-sm">Valid Until <span>31 December 2022</span></div>
                      <div class="flex flex-row">
                        <hr style="height:1px;border-width:0;color:black;background-color:gray; margin-left: 35px;"
                            class="text-black-light px-10 w-40 mt-1 self-center">
                        <img src="/images/icons/scissors.png" style="rotate: -90deg" class="w-6">
                      </div>
                    </div>
                    <div class="text-sm mt-1 lg:p-2 lg:pl-4" v-if="beforeDiscount">
                      <input type="text" v-model="this.promoCode"
                             class="w-32 h-8 border-2 border-black-light uppercase text-left pl-3 rounded-lg"
                             placeholder="">
                      <button v-if="beforeDiscount"
                              class="bg-blue text-white p-1.5 ml-2 pl-5 pr-5 mt-2 uppercase"
                              @click.prevent="discountApply()">Apply
                      </button>

                      <span class="text-xs text-red-600" v-if="this.promoErrorMsg">{{ this.promoErrorMsg }}</span>


                    </div>
                    <div class="flex flex-row justify-center" v-if="afterDiscount">
                      <div class="text-sm font-semibold text-green-700">Applied!</div>
                    </div>
                  </div>
                  <div class="bg-blue text-white text-lg p-2 rounded-tr-lg rounded-br-lg">
                    <div class="grid justify-items-center mt-1">
                      <img src="/images/icons/coupon-code.svg" class="w-10 mb-2">
                      <div class="">GET</div>
                      <div class="rotate-90">DISCOUNT</div>
                    </div>
                  </div>
                </div>


                <!--                    <button v-if="afterDiscount" class="text-red-600 border-2 border-red-600 text-xs font-semibold p-0.5 pl-2 pr-2 mt-2 hover:bg-red-600 hover:text-white"  @click.prevent="discountRemove()">Remove-->
                <!--                    </button>-->


                <!--                  <div class="p-1 ml-12" v-if="deal">-->
                <!--                    <div class="" v-if="afterDiscount">-->
                <!--                      <div class="text-sm font-semibold stroke-1 text-red-500"><s>${{ deal.bookValue.toLocaleString() }}</s></div>-->
                <!--                      <div class="text-lg font-semibold">${{ this.afterDiscountAmount.toLocaleString() }}</div>-->
                <!--                    </div>-->
                <!--                    <div class="" v-if="beforeDiscount">-->
                <!--                      <div class="text-lg font-semibold">${{ deal.bookValue.toLocaleString() }}</div>-->
                <!--                    </div>-->

                <!--                    <div class="">Purchase Price</div>-->
                <!--                  </div>-->

              </div>


              <button
                  @click.prevent="validateBidPay"
                  class="bg-blue uppercase text-white h-11 w-full lg:block hidden"
              >
                Put Hold on Card
              </button>

              <button @click.prevent="generalEnquiry"
                      class=" mt-6 bg-blue uppercase text-white h-11 w-full lg:block hidden">
                Get More Info
              </button>
            </div>

            <button
                @click.prevent="validateBidPay"
                class="bg-blue uppercase text-white p-4 mb-6 w-full lg:hidden block"
            >
              Put Hold on Card
            </button>

            <button
                @click.prevent="generalEnquiry"
                class="bg-blue uppercase text-white p-4 w-full lg:hidden block"
            >
              Get More Info
            </button>

          </div>
        </div>
      </div>
      <div v-if="isOtpModalVisible" class="flex justify-center relative w-full">
        <div
            class="
        w-full
        flex flex-col
        justify-center
        items-center
        text-center
        relative
      "
        >
          <p class="text-sm text-gray-medium lg:px-8 mb-10">
            Please enter the One Time Password sent to your registered mobile number
            {{ userMaskedPhone }}
          </p>
          <div class="w-full lg:px-6">
            <div class="w-full lg:px-16 text-4.5xl">
              <input
                  name="otp"
                  v-model.number="otp"
                  @keypress="isValidOtp($event)"
                  class="border rounded w-full text-center p-4 text-black-light"
              />
            </div>
            <!-- <OtpInput @value="handleOtpChange($event)" /> -->
          </div>
          <p v-if="passcodeSent" class="text-sm text-gray-medium px-8 mt-6 mb-4">
            Resend Passcode in <span>{{ toTime(remainingInterval) }}</span>
          </p>
          <a
              v-if="!passcodeSent"
              @click.prevent="resendPasscode"
              class="text-sm text-gray-medium px-8 mt-6 mb-4 cursor-pointer underline"
              href="#"
          >Resend</a
          >
          <div class="p-6 pt-0 lg:px-16 px-0 w-full">
            <div class="flex flex-col items-center p-8 lg:px-20 px-0">
              <button
                  @click.prevent="verifyOtp"
                  class="bg-blue uppercase text-white p-4 w-full mb-10"
              >
                Verify OTP
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>

  <Modal :showModalBg="false"
         bodyClass="lg:w-md-modal w-screen lg:h-auto justify-center m-auto p-5 min-h-screen lg:min-h-0"
         v-if="isFinanceModalVisible || isGeneralModalVisible" @close="closeModal">
    <template v-slot:header>
      <h1 v-if="!isThankYouModalVisible && isFinanceModalVisible" class="lg:text-3xl text-2xl font-display mt-2 lg:mb-10
      mb-5 text-center lg:px-12 lg:mx-12"> Financing a Pre-Owned Car is No More a Task
      </h1>

      <h1 v-if="!isThankYouModalVisible && isGeneralModalVisible" class="lg:text-3xl text-2xl font-display mt-2 lg:mb-10
      mb-5 text-center lg:px-12 lg:mx-12 uppercase"> Get In Touch
      </h1>
    </template>
    `

    <template v-slot:body>
      <div v-if="!isThankYouModalVisible" class="flex justify-center relative w-full">
        <div class="w-full flex flex-col justify-center items-center text-center lg:px-12 px-6
        relative">
          <div v-if="isFinanceModalVisible" class="lg:w-2/3 w-full flex flex-col items-center">
            <img class="lg:w-52 w-48" src="/images/finance-modal-icon.png"/>
          </div>
          <div v-if="isGeneralModalVisible" class="lg:w-2/3 w-full flex flex-col items-center">
            <img class="lg:w-52 w-48" src="/images/enquiry.png"/>
          </div>
          <p class="text-sm text-gray-medium lg:px-4 px-0 mt-6 mb-6" v-if="isFinanceModalVisible">
            Your downpayment would be $1,000 or more. Please fill in the following
            form and we will get back to you on more details related to your payment
            plan
          </p>
          <div class="p-6 pt-4 lg:px-12 px-0 w-full">
            <div class="bg-gray-lighter flex flex-col items-center lg:px-20 p-4 lg:mb-0 mb-6">
              <div class="relative w-full mb-4">
                <input class="border p-3 px-4 w-full" v-model="name" placeholder="Name"/>
              </div>
              <div class="relative w-full mb-4">
                <input class="border p-3 px-4 w-full" v-model="phone" placeholder="Phone"/>
              </div>
              <div class="relative w-full mb-4">
                <input class="border p-3 px-4 w-full" v-model="email" placeholder="Email"/>
              </div>
              <button v-if="isFinanceModalVisible" @click.prevent="saveEnquiry"
                      class="bg-blue uppercase text-white p-3 w-full block">
                Submit
              </button>
              <button v-if="isGeneralModalVisible" @click.prevent="saveGeneralEnquiry"
                      class="bg-blue uppercase text-white p-3 w-full block">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isThankYouModalVisible" class="flex justify-center relative w-full">
        <div class="w-full flex flex-col justify-center items-center text-center lg:px-12 py-12
        px-6 relative">
          <div class="lg:w-2/3 w-10/12 flex flex-col items-center mt-8">
            <img class="lg:w-36" src="/images/thankyou-modal-icon.png"/>
          </div>
          <h1 class="text-3xl font-display mt-8 mb-6 text-center lg:px-12 lg:mx-12 px-4 mx-4">
            Thank You
          </h1>
          <p class="text-sm text-gray-medium lg:px-4 px-0 mb-6">
            We will get back to you on this shortly.
          </p>
          <button @click.prevent="closeModal" class="bg-blue uppercase text-white p-3 px-6 block">
            Continue
          </button>
        </div>
      </div>
    </template>
  </Modal>


</template>
<script>
import axios from "axios";
import "vue-loading-overlay/dist/vue-loading.css";
// import OtpInput from "./OtpInput.vue";
import {useToast} from "vue-toastification";
import {mapGetters} from "vuex";
import Modal from "./Modal.vue";

export default {
  components: {
    Modal,
    // OtpInput,
  },
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  mounted() {
    if (this.deal && this.deal.currentBid) {
      this.yourBid = this.deal.fair_bid;
      this.bookValue = this.deal.bookValue;
    }
    this.paymentType = this.searchParams.is_price;
  },
  data() {
    return {
      remainingInterval: 30,
      passcodeInterval: 30,
      isModalVisible: false,
      isOtpModalVisible: false,
      isFinanceModalVisible: false,
      isGeneralModalVisible: false,
      isThankYouModalVisible: false,
      passcodeSent: false,
      yourBid: 10000,
      bookValue: 0,
      promoCode: '',
      afterDiscountAmount: '',
      discountAmount: 100,
      afterDiscount: false,
      beforeDiscount: true,
      promoErrorMsg: '',
      showKelly: false,
      isFavourite: false,
      timer: null,
      isLoading: false,
      name: "",
      email: "",
      phone: "",
      otpToken: "",
      otp: "",
      paymentType: "",
      arrivalDate: '',
      offerValidDate: '',
      promoId: ''
    };
  },
  computed: {
    ...mapGetters({
      deal: "selectedDeal",
      searchParams: "searchParams",
    }),
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    userMaskedPhone() {
      if (!this.currentUser) {
        return "";
      } else {
        let phone = this.currentUser.phone;
        return phone.replace(/\d(?=(?:\D*\d){4})/g, "*");
      }
    },
  },
  watch: {
    deal(newDeal) {
      console.log(this.deal.date);
      console.log('deal response');
      if (newDeal) {
        this.yourBid = newDeal.fair_bid;
        var options = {
          year: "numeric",
          month: "short",
          day: "2-digit",
        };

        const date = new Date(Date.parse(newDeal.date));
        const newDate = new Date(Date.parse(date.toDateString()));
        newDate.setDate(date.getDate() + 14);
        let getDate = newDate.toLocaleDateString("en-US", options);
        let dateParts = getDate.split(" ");
        let formatDate = dateParts[1].replace(/,/g, '') + '-' + dateParts[0] + '-' + dateParts[2];
        this.arrivalDate = formatDate;


        const validDate = new Date(Date.parse(newDeal.date));
        validDate.setDate(validDate.getDate() + 2);
        let validDateFormat = validDate.toLocaleDateString("en-US", options);
        let validFormat = validDateFormat.split(" ");
        let validFormatDate = validFormat[1].replace(/,/g, '') + '-' + validFormat[0] + '-' + validFormat[2];
        // console.log(formatDate);
        this.offerValidDate = validFormatDate;

      }
    },
  },
  methods: {
    getNextDate(daysToAdd = 0) {
      var options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0');
      let yy = today.getFullYear();


      today = mm + '-' + dd + '-' + yy;

      this.date = today;

      if (this.deal.date) {
        today = this.deal.date;
      }
      if (today && Date.parse(today)) {
        const date = new Date(Date.parse(today));

        const newDate = new Date(Date.parse(date.toDateString()));
        newDate.setDate(date.getDate() + daysToAdd);
        let getDate = newDate.toLocaleDateString("en-IN", options);
        let dateParts = getDate.split(" ");
        let formatDate = dateParts[0] + '-' + dateParts[1] + '-' + dateParts[2];
        return formatDate;
      }
    },
    discountApply() {
      if (this.promoCode) {
        this.$store.dispatch("promoCodeValidate", this.promoCode).then(
            (res) => {
              let resData = res.data
              if (resData.status == 1) {
                this.promoId = resData.data.id;
                this.beforeDiscount = false;
                this.afterDiscount = true;
                this.afterDiscountAmount = Math.round(this.deal.fair_bid * 0.1) - this.discountAmount;
              } else {
                this.promoErrorMsg = 'Invalid promo code';
              }

            },
            (error) => {
              let message = "Sorry! Something went wrong";
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                message = error.response.data.message;
              } else if (error.request) {
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
                message = error.message;
              }
              this.toast.error(message);
              console.log(error);
            }
        );
      } else {
        this.promoErrorMsg = 'Enter your promo code';
        return false;
      }


    },
    discountRemove() {
      this.beforeDiscount = true;
      this.afterDiscount = false;
    },
    trackEvent() {
      window.gtag('event', 'payment_10%');
    },
    togglePayment() {
      this.paymentType = !this.paymentType;
    },
    isFinance() {
      return !this.searchParams.is_price;
    },
    isNumber(evt, allowDot = true) {
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      if (allowDot) {
        keysAllowed.push(".");
      }
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
    isValidOtp(evt) {
      this.isNumber(evt, false);
      if ((this.otp + "").length >= 6) {
        evt.preventDefault();
      }
    },
    toTime(seconds) {
      var date = new Date(null);
      date.setSeconds(seconds);
      return date.toISOString().substr(14, 5);
    },
    gotoCardDetails() {
      document.body.classList.remove("overflow-hidden");

      this.$router.push({
        name: "Card Details",
        params: {
          vin: this.deal.id,
        },
      });
    },
    resendPasscode() {
      this.resendOtp();
    },
    handleOtpChange(event) {
      this.otp = event;
    },
    bidBookValue() {
      const value = Math.round(this.yourBid * 0.1);
      return value.toLocaleString();
    },
    showBidModal() {
      if (!this.loggedIn) {
        this.emitter.emit("showLogin", true);
        return;
      }
      this.$store.dispatch("setBidValue", this.yourBid);
      this.isModalVisible = true;
      this.isOtpModalVisible = false;
      document.body.classList.add("overflow-hidden");
      // this.updateBidToServer();
    },
    showFinanceModal() {
      this.isFinanceModalVisible = true;
      this.isThankYouModalVisible = false;
      document.body.classList.add("overflow-hidden");
      // this.updateBidToServer();
    },

    saveGeneralEnquiry() {
      if (!this.name) {
        this.toast.warning("Sorry! Name is required");
        return;
      }

      if (!this.phone) {
        this.toast.warning("Sorry! Phone is required");
        return;
      }

      if (!this.email) {
        this.toast.warning("Sorry! Email is required");
        return;
      }
      const data = {
        phone: this.phone,
        email: this.email,
        name: this.name,
        vin: this.deal.id,
        model: this.deal.year_make_model
      };
      this.$store.dispatch("generalEnquiry", data).then(
          (value) => {
            console.log(value);
            this.showThankYouModal();
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );
    },

    saveEnquiry() {
      if (!this.name) {
        this.toast.warning("Sorry! Name is required");
        return;
      }

      if (!this.phone) {
        this.toast.warning("Sorry! Phone is required");
        return;
      }

      if (!this.email) {
        this.toast.warning("Sorry! Email is required");
        return;
      }
      const data = {
        phone: this.phone,
        email: this.email,
        name: this.name,
        vin: this.deal.id,
        model: this.deal.year_make_model
      };
      this.$store.dispatch("financeEnquiry", data).then(
          (value) => {
            console.log(value);
            this.showThankYouModal();
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );
    },
    showThankYouModal() {
      this.isFinanceModalVisible = true;
      this.isThankYouModalVisible = true;
      document.body.classList.add("overflow-hidden");
      // this.updateBidToServer();
    },

    generalEnquiry() {
      this.isGeneralModalVisible = true;
      this.isThankYouModalVisible = false;
      this.isThankYouModalVisible = false;
      document.body.classList.add("overflow-hidden");
      // this.updateBidToServer();
    },

    validateBidPay() {
      if (!this.loggedIn) {
        this.emitter.emit("showLogin", true);
        return;
      }

      const params = {...this.deal};
      params["your_bid"] = this.yourBid;
      params["commission"] = Math.round(this.yourBid * 0.1);

      this.$store.dispatch("sendOtp", params).then(
          (value) => {
            console.log(value.data);
            this.otpToken = value.data.token;
            this.showOtpModal();
            window.gtag('event', 'otp_verification'); /* google analytic */
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );
    },

    verifyOtp() {
      const params = {};
      params["otp_token"] = this.otpToken;
      params["otp"] = this.otp;
      this.$store.dispatch("verifyOtp", params).then(
          (value) => {
            const params = {
              customer_id: this.currentUser.id,
              model: this.deal.title,
              vin: this.deal.id,
              retail_price: this.deal.bookValue,
              amount: Math.round(this.deal.fair_bid * 0.1) - this.discountAmount,
              after_discount_amount: this.afterDiscountAmount,
              promo_id: this.promoId,
            };
            if (this.promoId) {
              this.$store.dispatch("storeBuyers", params).then(
                  (value) => {
                    console.log(value.data);

                    const customerInfo = {
                      name: this.currentUser.first_name + ' ' + this.currentUser.first_name,
                      phone: this.currentUser.phone,
                      vin: this.deal.id,
                      model: this.deal.title,
                      promo_code: this.promoCode,
                    };

                    this.$store.dispatch("buyersMessage", customerInfo).then(
                        (value) => {
                          console.log(value);
                        },
                        (error) => {
                          this.toast.error("Sorry! Something went wrong");
                          console.log(error);
                        }
                    );

                    // this.$store.dispatch("teamMessage", customerInfo).then(
                    //     (value) => {
                    //       console.log(value);
                    //     },
                    //     (error) => {
                    //       this.toast.error("Sorry! Something went wrong");
                    //       console.log(error);
                    //     }
                    // );
                  },
                  (error) => {
                    let message = "Sorry! Something went wrong";
                    if (error.response) {
                      // The request was made and the server responded with a status code
                      // that falls out of the range of 2xx
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                      message = error.response.data.message;
                    } else if (error.request) {
                      console.log(error.request);
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      console.log("Error", error.message);
                      message = error.message;
                    }
                    this.toast.error(message);
                    console.log(error);
                  }
              );
            }


            this.$store.dispatch("card/setPaymentIntent", value.data.intent);
            this.gotoCardDetails();
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );
    },

    resendOtp() {
      const params = {};
      params["otp_token"] = this.otpToken;
      this.$store.dispatch("resendOtp", params).then(
          (value) => {
            console.log(value);
            this.otpToken = value.data.token;
            this.toast.success("OTP has been resent successfully!");
            this.passcodeSent = true;
            this.remainingInterval = this.passcodeInterval;
            const self = this;
            this.timer = setInterval(function () {
              self.remainingInterval--;
              if (self.remainingInterval <= 0) {
                clearInterval(self.timer);
                self.passcodeSent = false;
              }
            }, 1000);
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );
    },

    showOtpModal() {
      this.isModalVisible = true;
      this.isOtpModalVisible = true;
      document.body.classList.add("overflow-hidden");
    },
    closeModal() {
      this.isModalVisible = false;
      this.isOtpModalVisible = false;
      this.isFinanceModalVisible = false;
      this.isGeneralModalVisible = false;
      this.isThankYouModalVisible = false;
      document.body.classList.remove("overflow-hidden");
    },
    toggleKelly() {
      this.showKelly = !this.showKelly;
    },
    increment() {
      this.yourBid += 1000;
    },
    decrement() {
      this.yourBid -= 1000;
      this.yourBid = this.yourBid < 0 ? 0 : this.yourBid;
    },
    updateBidToServer() {
      const params = {
        fair_bid_userid: "hasnath@fairpy.com",
        fair_bid_value: this.yourBid,
        fair_bid_vin: this.deal.id,
      };
      this.isLoading = true;
      axios
          .get(
              `https://sy1dfwg4el.execute-api.us-west-2.amazonaws.com/default/setbid-cars`,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                crossDomain: true,
                params: params,
              }
          )
          .then((res) => {
            this.isLoading = false;
            console.log(res);
            this.toast.success("Bid Successfull");
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.toast.success("Sorry Unable to place bid");
          });
    },
    setFavourite() {
      if (!this.loggedIn) {
        this.emitter.emit("showLogin", true);
        return;
      }

      this.isFavourite = !this.isFavourite;
      if (this.isFavourite) {
        this.wishlistCars();
        this.updateFavouriteToServer();
      }
    },
    updateFavouriteToServer() {
      this.$store
          .dispatch("setFavourite", this.deal.id)
          .then((res) => {
            console.log(res);
            // this.toast.success("Added to Wishlist Successfully");
          })
          .catch((error) => {
            console.log(error);
            // this.toast.success("Sorry Unable to add to wishlist");
          });
    },

    wishlistCars() {
      const params = {...this.deal};
      this.$store
          .dispatch("setWishlistCars", params)
          .then((res) => {
            if (res.data.status == 1) {
              this.toast.success("Added to Wishlist Successfully");
            } else {
              this.toast.error("Already add your wish list cars");
            }
          })
          .catch((error) => {
            console.log(error);
            this.toast.error("Sorry Unable to add to wishlist");
          });
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 768px) {
  .object-custom-position {
    object-position: 0px -100px !important;
  }
}

.object-custom-position {
  object-position: 0px 0px;
}
</style>